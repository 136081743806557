import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Home } from 'react-feather'
import { Layout, SEO, VerticalCard, HMOSlider } from '../../components/common'
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap'
import ShowBlank from '../shared/ShowBlank'

const Careers = ({ pageContext }) => {
  const { topNav, accreditedHmos, careers } = pageContext
  const { favicon } = topNav
  return (
    <>
      <SEO title="Careers" favicon={favicon}/>
      <Layout activeNav='careers' data={topNav}>
        <Container className="my-4 md-mt-10 sm-mt-7">
          <Breadcrumb className="breadcrumb-wrapper mx-0">
            <Breadcrumb.Item href="/">
              <Home className="text-violet mr-2" size={14} />
              Home
          </Breadcrumb.Item>
            <Breadcrumb.Item active>Careers</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            {
              careers && careers.length > 0
                ? <Fragment>
                  {careers.map((data, i) =>
                    <Col lg={4} md={4} sm={12} className="my-3" key={i}>
                      <VerticalCard
                        img_url={data.banner}
                        title={data.title}
                        description={data.shortDescription}
                        slug={"/career/" + data.slug}
                      />
                    </Col>)}
                </Fragment>
                : <ShowBlank />
            }
          </Row>
        </Container>
        <HMOSlider data={accreditedHmos} />
      </Layout>
    </>
  )
}

Careers.propTypes = {
  pageContext: PropTypes.object.isRequired,
}

export default Careers
